@import '../global';

.page-header {
  width: 100%;
  position:fixed;
  z-index: 2;
  top:0;
  @include transition(background);
  @include theme-default {
    background: $theme-default-bg-primary;
  }

  @include theme-contrast {
    background: $theme-contrast-bg-primary;
    border-bottom:  3px solid $theme-contrast-bg-secondary;
  }

  &.page-header--aboutShown {
    @include theme-contrast {
      background: $theme-contrast-bg-secondary;
    }
  }
  @include phone-layout {
    height:toPhoneVw(330px);
    padding: toPhoneVw(32px) toPhoneVw(16px) toPhoneVw(26px) toPhoneVw(16px);
  }

  @include tablet-layout {
    height:toTabletVw(620px);
    padding: toTabletVw(32px) toTabletVw(80px) toTabletVw(26px) toTabletVw(80px);
  }
}

.page-header__top-bar {
  display: flex;
  @include phone-layout {
    margin-bottom: toPhoneVw(42px);
    padding: 0 toPhoneVw(20px);
  }
  @include tablet-layout {
    margin-bottom: toTabletVw(64px);
    width: 0 toTabletVw(0px);
  }
}

.page-header__logo {
  @include transition(filter);
  @include phone-layout {
    width: toPhoneVw(32px);
  }
  @include tablet-layout {
    width: toTabletVw(62px);
  }

  @include theme-contrast{
    filter: brightness(0);
  }
  
}


.page-header--aboutShown .page-header__logo {
  @include theme-contrast {
    filter: brightness(0) invert(1);
  }
}

.page-header__menu {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.page-header__menu-btn {
  @include phone-layout {
    &:not(:last-child) {
      margin-right: toPhoneVw(24px);
    }

    width: toPhoneVw(34px);
    height: toPhoneVw(34px);
  }
  @include tablet-layout {
    &:not(:last-child) {
      margin-right: toTabletVw(30px);
    }
    width: toTabletVw(64px);
    height: toTabletVw(64px);
  }
}

.page-header__theme-btn {
  stroke: transparent;
  border-radius: 50%;
  circle,path {
    @include transition(all);
  }
  @include transition(all);
  @include theme-default {
    circle {
      stroke:$theme-contrast-text-primary;
    }
    path{
      fill:$theme-contrast-text-primary;
    }
  }
  @include theme-contrast {
    circle {
      stroke:$theme-contrast-text-primary;
    }
    path{
      fill:$theme-default-primary;
    }
  }
}

.page-header__about-btn {
  path {
    @include transition(fill);
  }

  @include theme-default {
    path {
      fill: $theme-default-tertiary;
    }

    &.btn--active {
      path {
        fill: $theme-default-secondary;
      }
    }
  }

  @include theme-contrast {
    path {
      fill: $theme-contrast-primary;
    }

    &.btn--active {
      path {
        fill: $theme-contrast-secondary;
      }
    }
  }
}

.page-header__about {
  @include transition(opacity);
  position:fixed;
  z-index: 100;
  opacity: 0;
  pointer-events: none;
  opacity: 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;


  @include phone-layout{
    padding: toPhoneVw(64px) toPhoneVw(34px) toPhoneVw(64px) toPhoneVw(34px);
    top: 28vw;

  }
  @include tablet-layout{
    padding: toTabletVw(80px);
    top: 16vw;
  }

  @include theme-default {
    background: $theme-default-secondary;
    color:$theme-default-text-secondary;
  }

  @include theme-contrast {
    background: $theme-contrast-secondary;
    color:$theme-contrast-text-tertiary;
  }
}

.page-header--aboutShown .page-header__about {
  pointer-events: initial;
  opacity: 1;
}


.page-header__about-title{
  text-transform: uppercase;
  font-family: $font-primary;
  @include phone-layout{
    font-size: toPhoneVw(70px);
    margin-bottom: toPhoneVw(40px);

  }
  @include tablet-layout{
    font-size: toTabletVw(70px);
    margin-bottom: toTabletVw(40px);
  }
}

.page-header__about-description{
  font-family: $font-secondary;
  white-space: pre-wrap;
  @include phone-layout{
    font-size: toPhoneVw(25px);
    margin-bottom: toPhoneVw(20px);
    line-height: 1.2;
  }
  @include tablet-layout{
    font-size: toTabletVw(30px);
    margin-bottom: toPhoneVw(20px);
    line-height: 1.14;
  }
}

.page-header__about-content-wrapper {
  max-height: 50vh;
  overflow-y: scroll;
}
.page-header__about-close-btn {
  position:absolute;
  margin-left:auto;
  margin-right: auto;
  border:0;
  outline: 0;
  left:50%;
  transform:translateX(-50%);

  font-family: $font-secondary;

  @include theme-default {
    background-color: $theme-default-tertiary;
    color:$theme-default-text-secondary;
  }

  @include theme-contrast {
    background-color: $theme-contrast-bg-secondary;
    color:$theme-contrast-text-secondary;
    
  }

  @include phone-layout {
    width:toPhoneVw(208px);
    height:toPhoneVw(48px);
    font-size: toPhoneVw(26px);
    bottom: 4vh
  }

  @include tablet-layout {
    width:toTabletVw(208px);
    height:toTabletVw(48px);
    font-size: toTabletVw(26px);
    bottom: toTabletVw(70px);
  }


  &:hover,
  &:focus,
  &:active{
      outline: 0;
      border:0;
  }
   
}

.page-header__link{
  font-family: $font-secondary;
  color:$theme-contrast-text-secondary;
  @include phone-layout{
    font-size: toPhoneVw(25px);
    line-height: 1.2;
  }
  @include tablet-layout{
    font-size: toTabletVw(30px);
    line-height: 1.14;
  }
  &:link,
  &:hover,
  &:visited,
  &:active {
    font-family: $font-secondary;
    color:$theme-contrast-text-secondary;
  }
}


