@import '../global';



.media-player {

}

.media-player__infos-group {
  
  @include transition(all);
  display: flex;
  flex-direction: column;

  @include phone-layout {
    height: toPhoneVw(140px);
    padding: 0 toPhoneVw(20px);
  }
  @include tablet-layout {
    width: 0 toTabletVw(0px);
  }
  
  @include theme-default {
    color:$theme-default-text-primary;
  }

  @include theme-contrast {
    color:$theme-contrast-text-primary;
  }
}

.media-player__chapter {

  font-family: $font-secondary;
  @include phone-layout {
    font-size: toPhoneVw(16px);
    margin-bottom: toPhoneVw(8px) ;
  }

  @include tablet-layout {
    font-size: toTabletVw(20px);
    margin-bottom: toTabletVw(8px) ;
  }

}

.media-player__title {
  

  text-transform: uppercase;
  vertical-align: baseline;
  font-family: $font-primary;
  flex:1;
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @include phone-layout {
    max-width: 70%;
    font-size: toPhoneVw(28px);
    margin-bottom: toPhoneVw(18px);
    line-height: 1;

    & .text{
      flex:1;
      flex-shrink: 1;
      flex-grow: 1;
      line-height: 1;
      max-height: toPhoneVw(40px);
    }


  }
  @include tablet-layout {
    max-width: 90%;
    font-size: toTabletVw(60px);
    margin-bottom: toTabletVw(20px);

    & .text--small{
      font-size: toTabletVw(50px);
    }

    & .text{
      flex:1;
      flex-shrink: 1;
      flex-grow: 1;
      line-height: 1;
      max-height: toTabletVw(60px);
      min-height: toTabletVw(60px);
      height: toTabletVw(60px);
    }

  }

}

.media-player__progress-group {
  display: flex;
  align-items: center;

  @include phone-layout {
    margin-bottom: toPhoneVw(30px);
  }

  @include tablet-layout {
    margin-bottom: toTabletVw(56px);
  }

}

.media-player__progress-bar{
  flex :1;
  @include phone-layout{
    margin-right: toPhoneVw(16px);
  }

  @include tablet-layout{
    margin-right: toTabletVw(30px);
  }

}


.media-player__timer{
  text-align: right;
  font-family: $font-secondary;
  @include theme-default {
    color:$theme-contrast-text-tertiary;
  }

  @include theme-contrast {
    color:$theme-contrast-text-tertiary;
  }

  @include phone-layout {
    font-size: toPhoneVw(20px);
    width: toPhoneVw(68px);
  }

  @include tablet-layout {
    font-size: toTabletVw(20px);
    width: toTabletVw(68px);
  }

}

.media-player__controls{
  width:100%;
  display: flex;


  @include phone-layout {
    flex-direction: row;
    gap:toPhoneVw(58px);
  }

  @include tablet-layout {
    gap: toTabletVw(16px);
    flex-direction: column;
  }


}


.media-player__control-row{
  display: flex;
  flex-direction: row;

  @include phone-layout {
    gap: toPhoneVw(15px);
  }

  @include tablet-layout {
    gap: toTabletVw(16px);
  }
}


