@import '../global';

.media-list {
  display: flex;
  flex-direction: column;
  //position: absolute;
  z-index: 1;
  overflow-y: scroll;

  @include transition(background);

  @include theme-default {
    background: $theme-default-bg-secondary;
  }

  @include theme-contrast {
    background: $theme-contrast-bg-secondary;
  }


  @include phone-layout {
    padding: toPhoneVw(20px) toPhoneVw(16px) toPhoneVw(106px) toPhoneVw(16px);
    gap: toPhoneVw(10px);
  }

  @include tablet-layout {
    padding: toTabletVw(20px) toTabletVw(45px) toTabletVw(126px) toTabletVw(45px);
    gap: toTabletVw(25px);
  }
  
  @include phone-layout {
    height:calc(100vh - toPhoneVw(330px));
  }

  @include tablet-layout {
    height:calc(100vh - toTabletVw(620px));
  }
  
}

.media-list__item {
  width: 100%;
  position: relative;
  @include transition(all);


  &:after {
    content: ' ';
    position: absolute;
    mask-repeat: no-repeat;
    background-repeat: no-repeat;
    mask-position: right center;
    top:0;
    bottom:0;
    right: 0;
    height: 100%;
    pointer-events: none;
  }


  

  @include phone-layout {
    min-height: toPhoneVw(104px);
    padding:toPhoneVw(16px);
    &:after {
      right:toPhoneVw(25px);
      width:toPhoneVw(48px);
      height: 100%;
      mask-size: toPhoneVw(42px) toPhoneVw(42px);
    }
  }

  @include tablet-layout {
    &:after {
      right:toTabletVw(60px);
      width:toTabletVw(48px);
      height: 100%;
      mask-size: toTabletVw(42px) toTabletVw(42px);
    }
    min-height: toTabletVw(134px);
    padding:toTabletVw(45px);
  }




  &--passed {
    &:after {
      mask-image : url('../../assets/images/svg/replay.svg');
    }
    outline-offset: -3px;;
    @include theme-default {
      outline: 3px solid $theme-default-text-tertiary;
      &:after {
        background-color : $theme-default-tertiary;
      }
    }

    @include theme-contrast {
        outline: 3px solid $theme-contrast-text-secondary;
        &:after {
          background-color : $theme-contrast-text-secondary
        }
    }
  }

  &--current {
    &:after {
      mask-image : url('../../assets/images/svg/play.svg');
    }
    outline-offset: -6px;
    @include theme-default {

      background-color: $theme-default-primary;
      &:after {
        background-color : $theme-default-text-secondary
      }
    }

    @include theme-contrast {
      outline: 6px solid $theme-contrast-text-secondary;
      &:after {
        background-color : $theme-contrast-text-secondary
      }
    }
  }


  &--paused {
    &:after {
      mask-image : url('../../assets/images/svg/pause.svg');
    }
    @include theme-default {

    }

    @include theme-contrast {

    }
  }

  &--upcomming {
    &:after {
      mask-image : url('../../assets/images/svg/play.svg');
    }
    @include theme-default {
      background-color: $theme-default-tertiary;
      &:after {
        background-color : $theme-default-text-secondary
      }
    }

    @include theme-contrast {
      background-color: $theme-contrast-bg-primary;
      &:after {
        background-color : $theme-contrast-text-tertiary
      }
    }
  }
}


.media-list__item-description-wrapper{
    display: flex;
    flex-direction: column;
    flex:1;

}

.media-list__item-chapter {

    font-family: $font-secondary;
    text-align: left;

    @include phone-layout {
        font-size: toPhoneVw(16px);
    }

    @include tablet-layout {
        font-size: toTabletVw(20px);
    }

    .media-list__item--passed & {
        @include theme-default {
            color: $theme-default-text-tertiary;
        }

        @include theme-contrast {
            color: $theme-contrast-text-secondary;
        }

    }
    .media-list__item--current & {
        @include theme-default {
            color: $theme-default-text-secondary;
        }

        @include theme-contrast {
            color: $theme-contrast-text-secondary;
        }
    }
    .media-list__item--upcomming & {
        @include theme-default {
            color: $theme-default-text-secondary;
        }

        @include theme-contrast {
            color: $theme-contrast-text-tertiary;
        }
    }
}

.media-list__item-title {
    font-family: $font-primary;
    text-align: left;

    @include phone-layout {
        font-size: toPhoneVw(30px);
        line-height: 0.8;
        width: 80%;
    }

    @include tablet-layout {
        font-size: toTabletVw(40px);
    }

    .media-list__item--passed & {
        @include theme-default {
            color: $theme-default-text-tertiary;
        }

        @include theme-contrast {
            color: $theme-contrast-text-secondary;
        }

    }
    .media-list__item--current & {
        @include theme-default {
            color: $theme-default-text-secondary;
        }

        @include theme-contrast {
            color: $theme-contrast-text-secondary;
        }
    }

    .media-list__item--upcomming & {
        @include theme-default {
            color: $theme-default-text-secondary;
        }

        @include theme-contrast {
            color: $theme-contrast-text-tertiary;
        }
    }
}

.media-list__btn {
  width: 100%;
  height: 100%;

  outline: 0;
  border: 0;
  align-items: center;
  display: flex;

  background-color: transparent;

  &:hover,
  &:focus,
  &:active {
    outline: 0;
    border: 0;
    background: none;
  }
}
