$font-primary: 'Good Headline';
$font-secondary: 'Circular Std';

@font-face {
    font-family: 'Good Headline';
    src: url('../assets/fonts/Good-Headline.ttf')
}

@font-face {
    font-family: 'Circular Std';
    src: url('../assets/fonts//CircularStd-Bold.ttf')
}
