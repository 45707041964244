@import '../global';

.menu-bar-button{
    transition:all 350ms ease-in;
    border:0;
    outline: 0;
    background:none;
    &:hover,
    &:focus,
    &:active{
        outline: 0;
        border:0;
        background:none;
    }
    padding: 0;

    & img, &svg, & div {
        width:100%;
        height: 100%;
    }
}