@import '../global';

.media-player-btn {
    @include transition (all);
    border:0;
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    outline-offset:-3px;

    @include theme-default {
        background-color: $theme-default-primary;
        color:$theme-default-text-secondary;
    }

    @include theme-contrast{
        background-color: $theme-contrast-primary;
        color:$theme-default-text-secondary;
    }

    @include phone-layout {
        height: toPhoneVw(48px);
        width:toPhoneVw(48px)!important;
    }

    @include tablet-layout {
        height: toTabletVw(106px);
        gap:toTabletVw(4px);
    }


    &--selected {
        @include theme-default {
            background-color: $theme-default-secondary;
            color:$theme-default-text-secondary;
        }
    
        @include theme-contrast{
            background-color: $theme-contrast-secondary;
            color:$theme-contrast-text-primary;
            outline: 3px solid $theme-contrast-text-primary;
            &:hover,
            &:focus,
            &:active{
                outline: 3px solid $theme-contrast-text-primary;
            }
        }
    }


    &:hover,
    &:focus,
    &:active{
        outline: 0;
        border:0;
    }
}

.media-player-btn__icon {

    .media-player-btn--selected & {
        @include theme-contrast{
            filter:brightness(0);
        }
    
    }

    @include theme-default {
        
    }

 
    @include phone-layout {
        width:toTabletVw(80px);
    }

    @include tablet-layout {
        width:toTabletVw(45px);
    }
}



.media-player-btn__label {
    font-family: $font-secondary;

    @include theme-default {

    }

    @include theme-contrast{

    }

    @include phone-layout {
        font-size: toPhoneVw(0px);
    }

    @include tablet-layout {
        font-size: toTabletVw(18px);
    }
}
