// default theme
$theme-default-primary:#084CBC;
$theme-default-secondary:#EA5D37;
$theme-default-tertiary:#051C39;
$theme-default-text-primary:#084CBC;
$theme-default-text-secondary:#FFFFFF;
$theme-default-text-tertiary:#000000;
$theme-default-bg-primary:#FFFFFF;
$theme-default-bg-secondary:#E3E3E3;

// high contrast theme variables
$theme-contrast-primary:#000000;
$theme-contrast-secondary:#FFFFFF;
$theme-contrast-tertiary:#FFFFFF;
$theme-contrast-text-primary:#000000;
$theme-contrast-text-secondary:#FFFFFF;
$theme-contrast-text-tertiary:#000000;
$theme-contrast-bg-primary:#FFFFFF;
$theme-contrast-bg-secondary:#000000;

