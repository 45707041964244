@import 'global';
html, body{
    height: 100vh;
    min-height: -webkit-fill-available;
    overflow: hidden;
}

body{
    min-height: -webkit-fill-available;
}
.app{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.app__wrapper{
    overflow: hidden;
    @include landscape {
        display: none;
    }
}

.app__orientation-cta {
    width:100vw;
    height:100vh;
    display: none;
    font-family: $font-primary;
    text-transform: uppercase;
    align-items: center;
    justify-content: baseline;
    @include landscape {
        display: flex;
    }

    @include theme-default {
        background-color: $theme-default-secondary;
        color: $theme-default-text-secondary
    }

    @include theme-contrast {
        background-color: $theme-contrast-bg-secondary;
        color: $theme-default-text-secondary
    }

    @include phone-layout {
        font-size: toPhoneVw(20px);
        gap: toPhoneVw(40px);
        padding: 0  toPhoneVw(50px);
    }

    @include tablet-layout {
        font-size: toTabletVw(40px);
        gap: toTabletVw(160px);
        padding: 0  toTabletVw(120px);
    }
}

.app__orientation-cta-prompt {
    flex:1;
}


.app__orientation-cta-icon {
    @include phone-layout {
        width:toPhoneVw(80px);
    }

    @include tablet-layout {
        width:toTabletVw(150px);
        height:toTabletVw(calc(150px* 0.91));
    }
}

.app__content-push{
    @include phone-layout {
        height:toPhoneVw(330px);
        padding: toPhoneVw(32px) toPhoneVw(16px) toPhoneVw(26px) toPhoneVw(16px);
      }
    
      @include tablet-layout {
        height:toTabletVw(620px);
        padding: toTabletVw(32px) toTabletVw(80px) toTabletVw(26px) toTabletVw(80px);
      }
}

p,span, h1, h2, div {
    user-select: none;
}


