$breakpoint: 700px;
@mixin phone-layout {
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}

@mixin tablet-layout {
  @media screen and (min-width: $breakpoint) {
    @content;
  }
}

@mixin theme-default {
  .theme-default & {
    @content;
  }
}

@mixin theme-contrast {
  .theme-contrast & {
    @content;
  }
}

@mixin transition($field) {
  transition: $field 300ms ease-in;
}
@mixin landscape {
  @media (orientation: landscape) {
    @content;
  }
}

