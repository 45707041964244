@import '../global';
$border-radius: 2vw;
.progress-bar{
    @include transition(all);
    border-radius: $border-radius;
    overflow:hidden;
    width: 100%;

    @include theme-default {
        background: $theme-default-bg-secondary;

    }
    
    @include theme-contrast {
        background: $theme-contrast-secondary;
        border: 3px solid $theme-contrast-primary;
    }

    @include phone-layout {
        height: toPhoneVw(14px);
    }

    @include tablet-layout {
        height: toTabletVw(20px);
    }
}

.progress-bar__progress{
    border-radius: $border-radius;
    height: 100%;
    @include transition(color);
    @include theme-default {
        background: $theme-default-secondary;

    }
    
    @include theme-contrast {
        background: $theme-contrast-primary;
    }
}